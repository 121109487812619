export const Data = [
    {
        question: 'Why 4444 Supply and 0.44 SOL mint?',
        answer: 'We are planning on building something very big in here. This project is entirely community focused and we have huge plans for the future. We are not trying to create a project that will pump and dump after a few days, we are genuinely going to build a family. The plans we have with our Community Job Initiative will require some capital to start up and will take this discord and community to a whole other level compared to what is currently out there' 
    },
    {
        question: 'Is There a Whitelist?',
        answer: 'Magic Mushies are built different. We have decided to create an economy within the discord so that the most engaged people will be the ones on the whitelist. Post whitelist this economy will also have great use case, so get familiar with it. Be sure to participate in this as there are only 250 slots!' 
    },
    {
        question: 'Will the economy system be in place after mint?',
        answer: 'Yes. We have HUGE plans for the economy system post mint. You will definitely want to familiarize yourself with this.' 
    }
];