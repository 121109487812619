import React from 'react'
import { AboutContainer, AboutText, AboutTitle, AboutWrapper, ImageRow, ImageWrapper, MushieExample, AboutBox, PriceWrapper, PriceText } from './AboutElements'

import Preview1 from '../../img/about/Preview1.jpg'
import Preview2 from '../../img/about/Preview2.jpg'
import Preview3 from '../../img/about/Preview3.jpg'
import Preview4 from '../../img/about/Preview4.jpg'
import { AboutArea } from '../Hero/HeroElements'


const AboutSection = () => {
    return (
        <AboutContainer {...AboutArea}>
            <AboutWrapper>
                <AboutBox>
                    <AboutTitle>
                        MAGIC MUSHIES
                    </AboutTitle>
                    <AboutText>
                    It’s 2046. <br/> The world is consumed by cheap pixel rugs and cash-grab derivatives run by AI. One project every second. 4444 humans decided they had enough of this; they wanted to ascend. Day after day they began macrodosing Magic Mushies, unlocking brain power never seen before. They evolved to a point where they knew how to defeat the AI. They had to create REAL community, REAL friendship, and REAL utility the only cost being, they were now giant fucking mushrooms. Albeit; Magic Mushrooms.
                    </AboutText>
                    <PriceWrapper>
                    <PriceText>
                        4444 Supply
                    </PriceText>
                    <PriceText>
                        Price: 0.44 SOL 
                    </PriceText>
               
                    </PriceWrapper>
                </AboutBox>
                <ImageWrapper>
                    <ImageRow>
                        <MushieExample src = {Preview1}/>
                        <MushieExample src = {Preview2}/>
                    </ImageRow>
                    <ImageRow>
                        <MushieExample src = {Preview3}/>
                        <MushieExample src = {Preview4}/>
                    </ImageRow>
                </ImageWrapper>
            </AboutWrapper>
            </AboutContainer>
    )
}

export default AboutSection
