import React from 'react'
import { ImgContainer, TeamContainer, TeamMember, TeamTitle, TeamImg, TeamInfo, SocialIcon, TeamWrapper } from "../Team/TeamElements.js"
import SambinoIcon from "../../img/SambinoIcon.jpg"
import TeaIcon from "../../img/TeaIcon.png"
import GunterIcon from "../../img/GunterIcon.png"
import JangoIcon from "../../img/JangoIcon.png"
import ChadIcon from "../../img/ChadIcon.png"
import LocalGodIcon from "../../img/LocalGodIcon.png"
import RafikiIcon from "../../img/RafikiIcon.png"

import { FaTwitter } from 'react-icons/fa'

const Team = () => {
    return (
        <TeamContainer>
              <TeamTitle>
                TEAM
              </TeamTitle>
              <TeamWrapper>
              <ImgContainer>
  
                <TeamMember>
                <SocialIcon href="https://twitter.com/MushieGunter">
                      
                  <TeamImg src = {GunterIcon}/>

                  </SocialIcon>
                  <TeamInfo>
                    <h1>Gunter</h1>
                    Founder
                  </TeamInfo>
                  <SocialIcon>
                
                  </SocialIcon>
                </TeamMember>

                <TeamMember>
                <SocialIcon href="https://twitter.com/janbrek">
                     
                  <TeamImg src = {JangoIcon}/>
              
                  </SocialIcon>
                  <TeamInfo>
                    <h1>Jango</h1>
                    Artist
                  </TeamInfo>
                  <SocialIcon>
                  
                  </SocialIcon>
                </TeamMember>

                <TeamMember>
                <SocialIcon href="https://twitter.com/CryptoSambino">
                
                  <TeamImg src = {SambinoIcon}/>
              
                  </SocialIcon>
                  <TeamInfo>
                    <h1>Sambino</h1>
                    Developer
                  </TeamInfo>
                  <SocialIcon>
                   
                  </SocialIcon>
                </TeamMember>

                <TeamMember>
                <SocialIcon href="https://twitter.com/ratedteaz">
                   
                  <TeamImg src = {TeaIcon}/>
       
                  </SocialIcon>
                  <TeamInfo>
                    <h1>TeaTree</h1>
                    Community Lead
                  </TeamInfo>
                  <SocialIcon>
                   
                  </SocialIcon>
                </TeamMember>
  
              </ImgContainer>

      <ImgContainer>
              
              <TeamMember>
                <SocialIcon href="https://twitter.com/lander_crypto">
                <TeamImg src = {ChadIcon}/>
                </SocialIcon>
                <TeamInfo>
                  <h1>Magic Chad</h1>
                  Head Mod
                </TeamInfo>
                <SocialIcon></SocialIcon>
              </TeamMember>

              <TeamMember>
                <SocialIcon href="https://twitter.com/localgodofwar">
                <TeamImg src = {LocalGodIcon}/>
                </SocialIcon>
                <TeamInfo>
                  <h1>LocalGodofWar</h1>
                   Head Mod
                </TeamInfo>
                <SocialIcon></SocialIcon>
              </TeamMember>

              <TeamMember>
                <SocialIcon href="https://twitter.com/AFG929">

             
                <TeamImg src = {RafikiIcon}/>

                </SocialIcon>
                <TeamInfo>
                  <h1>Rafiki</h1>
                  Head Mod
                </TeamInfo>
                <SocialIcon></SocialIcon>
              </TeamMember>

            </ImgContainer>
            </TeamWrapper>
      </TeamContainer>
    )
}

export default Team
