import React, {useState, useEffect} from 'react'
import { BarAndText, Phase, PhaseContainer, PhaseImg, PhaseTitle, RoadmapContainer, RoadmapGoal, RoadmapText, RoBar, UpArrow, ButtonDiv, DownArrow } from './RoadmapElements'
import UpArrowClick from './RoadmapGoals'
import PhaseIcon from '../../img/Roadmap/PhaseLogo.png'
import RoadBar from '../../img/Roadmap/RoBar.png'
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'


const RoadmapGoals = () => {
    

    const [PhaseNumber, setPhaseNumber] = useState(0);
    
    if (PhaseNumber < 1){

        
    return (
        <RoadmapContainer>
            <ButtonDiv>
                
            </ButtonDiv>
        <PhaseContainer>
        <Phase>
            <PhaseImg src={PhaseIcon}/>
            <PhaseTitle style={{ color: "#7c76da"}}>
                PRE-MINT
            </PhaseTitle>
        </Phase>
        <BarAndText>
            <RoBar src={RoadBar}/>
            <RoadmapText>
                <RoadmapGoal>-Trip So Hard, Mushies Come to Life</RoadmapGoal>
                <RoadmapGoal>-Build Amazing Community & Discord</RoadmapGoal>
                <RoadmapGoal>-SUPER Engaging Whitelist Event</RoadmapGoal>
                <RoadmapGoal>-Partner with Huge California Smoke Shop (Ziggy's)</RoadmapGoal>
                <RoadmapGoal>-Mint Announcement (Jan 5th 8PM UTC)</RoadmapGoal>
            </RoadmapText>
        </BarAndText>
        
    </PhaseContainer>
      <ButtonDiv>
      <DownArrow onClick={() => setPhaseNumber(PhaseNumber + 1)}>
          <TiArrowSortedDown/>
      </DownArrow>
  </ButtonDiv>
  
  </RoadmapContainer>
    
    )
    }
    else if (PhaseNumber == 1)
    {
        return (
            <RoadmapContainer>
            <ButtonDiv>
                <UpArrow onClick={() => setPhaseNumber(PhaseNumber - 1)}>
                    <TiArrowSortedUp/>
                </UpArrow>
            </ButtonDiv>
            <PhaseContainer>
            <Phase>
                <PhaseImg src={PhaseIcon}/>
                <PhaseTitle style={{ color: "#7c76da"}}>
                    PHASE 1 
                </PhaseTitle>
            </Phase>
            <BarAndText>
                <RoBar src={RoadBar}/>
            <RoadmapText>
                <RoadmapGoal>-HUGE MINT / POST-MINT GIVEAWAY</RoadmapGoal>
                <RoadmapGoal>-Secondary Market Listings (MagicEden, AlphaArt)</RoadmapGoal>
                <RoadmapGoal>-Community Job Introduction</RoadmapGoal>
                <RoadmapGoal>-P2E Discord Game w/ Lore Driven Shop!</RoadmapGoal>
                
            </RoadmapText>
            </BarAndText>
        
    </PhaseContainer>
        <ButtonDiv>
            <DownArrow onClick={() => setPhaseNumber(PhaseNumber + 1)}>
                <TiArrowSortedDown/>
            </DownArrow>
        </ButtonDiv>
        
        </RoadmapContainer>
            ) 
    }
    else if (PhaseNumber == 2)
    {
        return (
            <RoadmapContainer>
            <ButtonDiv>
                <UpArrow onClick={() => setPhaseNumber(PhaseNumber - 1)}>
                    <TiArrowSortedUp/>
                </UpArrow>
            </ButtonDiv>
            <PhaseContainer>
            <Phase>
                <PhaseImg src={PhaseIcon}/>
                <PhaseTitle style={{ color: "#7c76da"}}>
                    COMMUNITY JOBS
                </PhaseTitle>
            </Phase>
            <BarAndText>
                <RoBar src={RoadBar}/>
            <RoadmapText>
        
                <RoadmapGoal>-Weekly Market Scan</RoadmapGoal>
                <RoadmapGoal>-Trade Analysis Teacher</RoadmapGoal>
                <RoadmapGoal>-Gaming Event Organizer</RoadmapGoal>
                <RoadmapGoal>-Sticker & Emoji Maker</RoadmapGoal>
                <RoadmapGoal style={{fontFamily: "Marker", justifyContent: "center", textAlign: "center"}}> AND MUCH MORE!!</RoadmapGoal>
                
            </RoadmapText>
            </BarAndText>
        
    </PhaseContainer>
        <ButtonDiv>
            <DownArrow onClick={() => setPhaseNumber(PhaseNumber + 1)}>
                <TiArrowSortedDown/>
            </DownArrow>
        </ButtonDiv>
        
        </RoadmapContainer>
            ) 
    }
    else if (PhaseNumber == 3)
    {
        return (
            <RoadmapContainer>
            <ButtonDiv>
                <UpArrow onClick={() => setPhaseNumber(PhaseNumber - 1)}>
                    <TiArrowSortedUp/>
                </UpArrow>
            </ButtonDiv>
            <PhaseContainer>
            <Phase>
                <PhaseImg src={PhaseIcon}/>
                <PhaseTitle style = {{ color: "#7c76da"}}>
                    PHASE 2
                </PhaseTitle>
            </Phase>
            <BarAndText>
                <RoBar src={RoadBar}/>
            <RoadmapText>
                <RoadmapGoal>-Continue Expanding on Community Jobs</RoadmapGoal>
                <RoadmapGoal>-Upgraded P2E Discord Game Experience</RoadmapGoal>
                <RoadmapGoal>-Update Website to Dynamic Leaderboards and Shop</RoadmapGoal>
            
                <RoadmapGoal>***TO BE CONTINUED IN ROADMAP 2.0***</RoadmapGoal>
            </RoadmapText>
            </BarAndText>
        
    </PhaseContainer>
        <ButtonDiv>
            
        </ButtonDiv>
 
        </RoadmapContainer>
            ) 
    }
}





const RoadmapSegment = () => {

    return (
       <RoadmapGoals/>
    )
}

export default RoadmapSegment
